var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: _vm.editingBundle, "max-width": "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: dialog }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { right: "", disabled: !_vm.showTooltip },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: tooltip }) {
                          return [
                            _c(
                              "div",
                              _vm._g({}, { ...tooltip, ...dialog }),
                              [
                                _vm._t("dialogactivator", function () {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          icon: "",
                                          text: "",
                                          color: "secondary",
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("edit")])],
                                      1
                                    ),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_c("span", [_vm._v("Edit")])]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.editBundleDialog,
        callback: function ($$v) {
          _vm.editBundleDialog = $$v
        },
        expression: "editBundleDialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center w-100" },
              [
                _c("v-icon", [_vm._v("edit")]),
                _c(
                  "span",
                  { staticClass: "text-uppercase secondary--text ml-1" },
                  [_vm._v("Edit " + _vm._s(_vm.bundleData.bundle_type_name))]
                ),
                _c("v-spacer"),
              ],
              1
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _vm.highlightHandBack
                ? _c(
                    "v-alert",
                    { staticClass: "mt-6", attrs: { type: "info" } },
                    [
                      _vm._v(
                        " Enable handback visibility below for students to see their corrections and grades "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c("v-col", { attrs: { cols: "12", md: "5" } }, [
                    _c("span", { staticClass: "subtitle-2" }, [
                      _vm._v("Handback Visibility"),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: { row: "" },
                          model: {
                            value: _vm.showBundleHandbacks,
                            callback: function ($$v) {
                              _vm.showBundleHandbacks = $$v
                            },
                            expression: "showBundleHandbacks",
                          },
                        },
                        [
                          _c("v-radio", {
                            attrs: { label: "Hidden", value: false },
                          }),
                          _c("v-radio", {
                            attrs: { label: "Visible", value: true },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.highlightHandBack
                ? _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "5" } }, [
                        _c("span", { staticClass: "subtitle-2" }, [
                          _vm._v("Assignment description"),
                        ]),
                      ]),
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            attrs: { rows: "2", outlined: "" },
                            model: {
                              value: _vm.bundleDescription,
                              callback: function ($$v) {
                                _vm.bundleDescription = $$v
                              },
                              expression: "bundleDescription",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.highlightHandBack
                ? _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "5" } }, [
                        _c("span", { staticClass: "subtitle-2" }, [
                          _vm._v("Hand-in deadline"),
                        ]),
                      ]),
                      _c("v-col", [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column" },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "close-on-content-click": false,
                                  "nudge-right": 40,
                                  transition: "scale-transition",
                                  "offset-y": "",
                                  "min-width": "auto",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    readonly: "",
                                                    label: "Date",
                                                    outlined: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.bundleDeadlineDate,
                                                    callback: function ($$v) {
                                                      _vm.bundleDeadlineDate =
                                                        $$v
                                                    },
                                                    expression:
                                                      "bundleDeadlineDate",
                                                  },
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  374352365
                                ),
                                model: {
                                  value: _vm.bundleDateMenu,
                                  callback: function ($$v) {
                                    _vm.bundleDateMenu = $$v
                                  },
                                  expression: "bundleDateMenu",
                                },
                              },
                              [
                                _c("v-date-picker", {
                                  on: {
                                    input: function ($event) {
                                      _vm.bundleDateMenu = false
                                    },
                                  },
                                  model: {
                                    value: _vm.bundleDeadlineDate,
                                    callback: function ($$v) {
                                      _vm.bundleDeadlineDate = $$v
                                    },
                                    expression: "bundleDeadlineDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "close-on-content-click": false,
                                  transition: "scale-transition",
                                  "offset-y": "",
                                  "max-width": "310px",
                                  "min-width": "310px",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    label: "Time",
                                                    readonly: "",
                                                    outlined: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.bundleDeadlineTime,
                                                    callback: function ($$v) {
                                                      _vm.bundleDeadlineTime =
                                                        $$v
                                                    },
                                                    expression:
                                                      "bundleDeadlineTime",
                                                  },
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2542133453
                                ),
                                model: {
                                  value: _vm.bundleTimeMenu,
                                  callback: function ($$v) {
                                    _vm.bundleTimeMenu = $$v
                                  },
                                  expression: "bundleTimeMenu",
                                },
                              },
                              [
                                _vm.bundleTimeMenu
                                  ? _c("time-picker", {
                                      attrs: {
                                        "use-seconds": "",
                                        format: "24hr",
                                      },
                                      model: {
                                        value: _vm.bundleDeadlineTime,
                                        callback: function ($$v) {
                                          _vm.bundleDeadlineTime = $$v
                                        },
                                        expression: "bundleDeadlineTime",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.bundleHandinDatetime
                              ? _c("div", { staticClass: "d-flex" }, [
                                  _c("span", { staticClass: "mr-1" }, [
                                    _vm._v("Displayed deadline:"),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-medium" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateTimeToHuman")(
                                            _vm.bundleHandinDatetime
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-divider", { staticClass: "my-4" }),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("div", [
                        _vm._v(
                          "Add a message to notify students of changes (e.g. due date updated, grades now visible) or select no notifications below"
                        ),
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          disabled: _vm.skipNotify,
                          height: "100",
                          placeholder: "Please detail your changes here",
                          outlined: "",
                        },
                        model: {
                          value: _vm.updateMessage,
                          callback: function ($$v) {
                            _vm.updateMessage = $$v
                          },
                          expression: "updateMessage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-checkbox", {
                        attrs: { label: "Do not notify students" },
                        model: {
                          value: _vm.skipNotify,
                          callback: function ($$v) {
                            _vm.skipNotify = $$v
                          },
                          expression: "skipNotify",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-card-actions", [
            _c(
              "div",
              { staticClass: "d-flex align-center justify-end w-100" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.editingBundle,
                      color: "secondary",
                      text: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.editBundleDialog = false
                      },
                    },
                  },
                  [_vm._v("close")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      loading: _vm.editingBundle,
                      disabled: _vm.disableEditButton,
                      text: "",
                      color: "primary",
                    },
                    on: { click: _vm.editBundle },
                  },
                  [
                    !_vm.skipNotify
                      ? _c("span", [_vm._v("Save & Notify")])
                      : _c("span", [_vm._v("Save")]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }